class megaMenu {
    selector_link = 'a[data-main-menu].has-child';
    all_niv_1 = null;
    selector_link_niv_2 = '[data-main-sub-menu]';
    all_niv_2 = null;
    retour = ".retour";
    attribute_miv_1 = "data-main-menu";
    attribute_miv_2 = "data-main-sub-menu";

    constructor() {
        this.all_niv_1 = document.querySelectorAll(this.selector_link);
        this.all_niv_2 = document.querySelectorAll(this.selector_link_niv_2);
        this.listen();
    }

    listen(){
        if (this.all_niv_1.length > 0){
            this.all_niv_1.forEach((elm) => {
                elm.addEventListener('click', () => {
                    const menuId = elm.getAttribute(this.attribute_miv_1);
                    this.activateSub(menuId);
                    elm.classList.add('--active');
                });

            });
        }

        const retour = document.querySelectorAll(this.retour);
        if(retour.length > 0){
            retour.forEach((back) => {
                back.addEventListener('click', () => {
                    this.resetSubMenu();
                });

            });
        }
    }

    activateSub(menuId){
        if(menuId){
            this.resetSubMenu();
            const target = document.querySelector('['+this.attribute_miv_2+'="'+menuId+'"]')
            if(target){
                target.classList.add('--active');
            }
        }
    }

    resetSubMenu(){
        if (this.all_niv_1.length > 0){
            this.all_niv_1.forEach((elm) => {
                elm.classList.remove('--active');
            });
        }

        if (this.all_niv_2.length > 0){
            this.all_niv_2.forEach((elm) => {
                elm.classList.remove('--active');
            });
        }
    }




}

const megaMenuThuria = new megaMenu();