// PAGE LISTING SIT
function callbackMapWithPopup(macarte) {
    macarte.zoomControl.setPosition('topright');

    var markers = [];
    $('.js-results .card', $(macarte._container).parents('.bloc')).each(function () {
        const latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        const marker = th_maps.createMarker(macarte, latlng, 'default');
        console.log(marker);

        macarte.on("click", function (e) {
            marker.setIcon(th_maps.markersIcons['default']);
        });

        // Lorsqu'on ouvre un popup on change l'icône pour ensuite remettre à la fermeture celle-là de default
        marker.on("popupopen", (e) => setMarkerState(marker, 'hover') );
        marker.on("popupclose", (e) => setMarkerState(marker, 'default') );

        // Lorsqu'on fait un hover sur un marqueur on change l'icône
        marker.on("mouseover", (e) => setMarkerState(marker, 'hover') );
        marker.on("mouseout", (e) => setMarkerState(marker, 'default') );

        // Lorsqu'on fait un hover sur une card, on change l'icône du marqueur
        this.addEventListener('mouseenter', (e) => setMarkerState(marker, 'hover') );
        this.addEventListener('mouseleave', (e) => setMarkerState(marker, 'default') );

        // vu que les cards de base on du lazy load, on va supprimer ce qui pourrait bloquer l'affichage des images si le lazy n'est pas encore passé à ce moment là
        let card_html = this.outerHTML;
        let lazy_processed_tag = 'data-was-processed="true"';
        let lazy_loaded = card_html.indexOf(lazy_processed_tag);

        if (lazy_loaded == -1) {
            card_html = card_html.replace('src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"', '');
            card_html = card_html.replace('data-src=', 'src=');
            card_html = card_html.replace('lazy', '');
        }

        th_maps.createInfoWindow(card_html, marker, 260);
        markers.push(marker);
    });

    // Change l'état d'un marker
    function setMarkerState(marker, state) {
        if(state === 'hover') {
            marker.setIcon(th_maps.markersIcons['hover']);
            marker.setZIndexOffset(1000);        // passe le marker au-dessus des autres
        } else {
            marker.setIcon(th_maps.markersIcons['default']);
            marker.setZIndexOffset(0);
        }
    }

    // Recentre sur les points seulement s'il n'y a pas de KML (sinon centrage sur le KML par défaut)
    const el = macarte._container;

    if (!el.getAttribute('data-kml')) {
        if(markers){
            const group = new L.featureGroup(markers);
            macarte.fitBounds(group.getBounds());
        }

    }


    if (isTouchDevice()) {
        macarte.dragging.disable();
    }

}
