function callbackMapBlockSIT(macarte) {
    macarte.zoomControl.setPosition('topright');

    const latlng = macarte.getCenter();
    const marker = th_maps.createMarker(macarte, latlng, 'default');

    console.log(marker);

    macarte.setZoom(14);

    if (isTouchDevice()) {
        macarte.dragging.disable();
    }
}