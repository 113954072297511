(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-galerie');
    sliders.forEach(slider => {
        const sliderDirection = slider.classList.contains('--direction-rtl') ? 'rtl' : 'ltr';

        const splide = new Splide(slider, {
            gap: 40,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
            pagination: true,
            direction: sliderDirection,

            breakpoints: {
                980: {
                    gap: 20,
                },
                600: {
                    arrows: false,
                    gap: 10,
                    direction: 'ltr',       // direction toujours de gauche à droite en mobile
                },
            }
        }).mount();
    });

})();