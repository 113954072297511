class evin {
    storageName = "civp_loi_evin";
    selector = {
        popup : ".js-evin",
        accept : ".js-accept"
    }
    popup;
    accept;

    constructor() {
        this.popup = document.querySelector(this.selector.popup);
        this.accept = document.querySelector(this.selector.accept);

        if (!this.testLocalstorage()){
            return false;
        }

        if(this.popup && this.accept && !this.getStore()){
            this.show();
            this.listen();
        }
    }

    show(){
        this.popup.classList.add('--open');
    }

    listen(){
        this.accept.addEventListener("click", () => {
            this.setStore();
            this.popup.classList.remove('--open');
        });
    }

    testLocalstorage() {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    getStore() {
        const store = localStorage.getItem(this.storageName);
        if (store && store === "1") {
            return true;
        }
        return false;
    }

    setStore(){
        localStorage.setItem(this.storageName, "1");
    }

}

const alerteEvin = new evin();