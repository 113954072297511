(function () {

    class BlocRelation {
        constructor(groupNode) {
            this.bloc = groupNode;

            // Init des sliders
            this.slidersArray = [];
            const sliders = this.bloc.querySelectorAll('.js-slider-relation');
            sliders.forEach((slider) => {
                this.initSlider(slider);
            });

            this.tablistNode = this.bloc.querySelector('[role=tablist]');

            this.tabs = [];

            this.firstTab = null;
            this.lastTab = null;

            // Pas de gestion des tabs si un seul onglet / slider
            if (this.tablistNode) {
                this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'));
                this.tabpanels = [];

                this.tabs.forEach((tab) => {
                    const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

                    tab.tabIndex = -1;
                    tab.setAttribute('aria-selected', 'false');
                    this.tabpanels.push(tabpanel);

                    tab.addEventListener('keydown', this.onKeydown.bind(this));
                    tab.addEventListener('click', this.onClick.bind(this));

                    if (!this.firstTab) {
                        this.firstTab = tab;
                    }
                    this.lastTab = tab;
                });

                this.setSelectedTab(this.firstTab, false);
            }
        }

        // Init des sliders
        initSlider(slider) {
            
            const splide = new Splide(slider, {
                gap: 40,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
                pagination: true,

                breakpoints: {
                    600: {
                        arrows: false,
                        gap: 10,
                    },
                }
            }).mount();
            this.slidersArray.push(splide);
        }

        setSelectedTab(currentTab, setFocus) {
            if (typeof setFocus !== 'boolean') {
                setFocus = true;
            }

            this.tabs.forEach((tab) => {
                const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

                // Active le tab courant
                if (currentTab === tab) {
                    tab.setAttribute('aria-selected', 'true');
                    tab.removeAttribute('tabindex');

                    tabpanel.classList.remove('is-hidden');
                    if (setFocus) {
                        tab.focus();
                        // tab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    }

                    // refresh du slider splide quand on change d'onglet
                    const splideId = tab.dataset.splideId;
                    const splide = this.slidersArray[splideId];
                    // splide.emit('resize');

                    // Désactive les tabs précédents
                } else {
                    tab.setAttribute('aria-selected', 'false');
                    tab.tabIndex = -1;

                    tabpanel.classList.add('is-hidden');
                }
            });
        }

        // Sélectionne le tab précédent avec les flèches
        setSelectedToPreviousTab(currentTab) {
            if (currentTab === this.firstTab) {
                this.setSelectedTab(this.lastTab);
            } else {
                const index = this.tabs.indexOf(currentTab);
                this.setSelectedTab(this.tabs[index - 1]);
            }
        }

        // Sélectionne le tab suivant avec les flèches
        setSelectedToNextTab(currentTab) {
            if (currentTab === this.lastTab) {
                this.setSelectedTab(this.firstTab);
            } else {
                const index = this.tabs.indexOf(currentTab);
                this.setSelectedTab(this.tabs[index + 1]);
            }
        }

        /* EVENT HANDLERS */

        onKeydown(event) {
            let tgt = event.currentTarget,
                flag = false;

            switch (event.key) {
                case 'ArrowLeft':
                    this.setSelectedToPreviousTab(tgt);
                    flag = true;
                    break;

                case 'ArrowRight':
                    this.setSelectedToNextTab(tgt);
                    flag = true;
                    break;

                case 'Home':
                    this.setSelectedTab(this.firstTab);
                    flag = true;
                    break;

                case 'End':
                    this.setSelectedTab(this.lastTab);
                    flag = true;
                    break;

                default:
                    break;
            }

            if (flag) {
                event.stopPropagation();
                event.preventDefault();
            }
        }

        onClick(event) {
            this.setSelectedTab(event.currentTarget);
        }
    }


    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-relation');
    blocs.forEach(function (bloc) {
        new BlocRelation(bloc);
    });

})();
